export const TEMPLATE_TYPE_KEY = [
    "Homecare",
    "Services",
    "Religion",
    "Food",
    "Teaching"
];
export const AppPriceSymbol = {
    English: '$',
    Thai: '฿'
};
export const DateFormatSelection = [
    { label: "Today", value: 'day' },
    { label: "Week", value: 'week' },
    { label: "Current Month", value: 'currentMonth' },
    { label: "Last Month", value: 'lastMonth' },
    { label: "Year", value: 'year' },
    { label: "Custom Date", value: 'customDate' },
    { label: "Month of Month", value: 'mom' },
    { label: "Year of Year", value: 'yoy' }
];
export const ChartType = [
    { label: "Line", value: 'line' },
    { label: "Bar", value: 'bar' },
    { label: "Area", value: 'area' },
    { label: "Pie", value: 'pie' },
    // { label: "Column", value: 'column' }
];
export const SelectTopResults = [
    { label: "Top 10", value: '10' },
    { label: "Top 20", value: '20' },
    { label: "Top 25", value: '25' },
    { label: "Top 50", value: '50' },
    { label: "Top 100", value: '100' },
];
export const ROLE = {
    "SUPERADMIN": "SuperAdmin",
    "ADMIN": "Admin",
    "CLIENT": "Client",
    "VENDOR": "Vendor",
    "MANAGER": "Manager"
};
export const OrderStatus = {
    "pending": "Pending",
    "partialProcess": "Processing",
    "delivered": "Delivered",
    "shipped": "Processing",
    "canceled": "Canceled",
    "refunded": "Refunded"
}

let addonAppModules = [
    {
        name: "Video",
        key: "video",
        isAddon: true,
        url: "https://dummyimage.com/300x400&text=video",
        associtaeWith: ["Homecare", "Services", "Religion", "Food", "Teaching"],
    },
    {
        name: "DP",
        key: "dp",
        isAddon: true,
        url: "https://dummyimage.com/300x400&text=dp",
        associtaeWith: ["Homecare", "Services", "Religion", "Food", "Teaching"],
    },
    {
        name: "Website",
        key: "website",
        url: "https://dummyimage.com/300x400&text=website",
        isAddon: true,
        associtaeWith: ["Homecare", "Services", "Religion", "Food", "Teaching"],
    }
];

export const appModules = [
    {
        name: "Polls",
        key: "polls",
        url: "https://encaptture.s3.ap-south-1.amazonaws.com/app-images/Images/10-Trivia2.jpg",
        associtaeWith: ["Homecare", "Services", "Religion", "Food", "Teaching"],
    },
    {
        name: "Lists",
        key: "lists",
        url: " https://encaptture.s3.ap-south-1.amazonaws.com/app-images/Images/10-Trivia1.jpg",
        associtaeWith: ["Homecare", "Services", "Religion", "Food", "Teaching"],
    },
    {
        name: "Documents Manager",
        key: "documentsManager",
        url: "https://encaptture.s3.ap-south-1.amazonaws.com/app-images/Images/9-Document.jpg",
        associtaeWith: ["Homecare", "Services", "Religion", "Food", "Teaching"],
    },
    {
        name: "Comment Board-QuickBlox",
        key: "commentBoardQuickBlox",
        url: "https://encaptture.s3.ap-south-1.amazonaws.com/app-images/Images/7-CommentBoard.jpg",
        associtaeWith: ["Homecare", "Services", "Religion", "Food", "Teaching"],
    },
    {
        name: "Private Messaging-QuickBlox",
        key: "privateMessagingQuickBlox",
        url: "https://encaptture.s3.ap-south-1.amazonaws.com/app-images/Images/8-Private-Messages.jpg",
        associtaeWith: ["Homecare", "Services", "Religion", "Food", "Teaching"],
    },
    // {
    //     name: "Dashboard",
    //     key: "dashboard",
    //     url: "https://dummyimage.com/300x400&text=dashboard",
    //     associtaeWith: ["Services", "Religion", "Food", "Teaching"],
    // },
    // {
    //     name: "Forms",
    //     key: "forms",
    //     url: "https://dummyimage.com/300x400&text=forms",
    //     associtaeWith: ["Homecare", "Services", "Religion", "Food"],
    // },
    // {
    //     name: "Trivia Challenge",
    //     key: "triviaChallenge",
    //     url: "https://dummyimage.com/300x400&text=triviaChallenge",
    //     associtaeWith: ["Homecare", "Services", "Religion"],
    // },
    // {
    //     name: "Weblinks",
    //     key: "weblinks",
    //     url: "https://dummyimage.com/300x400&text=weblinks",
    //     associtaeWith: ["Homecare", "Services",],
    // },
    // {
    //     name: "Surveys",
    //     key: "surveys",
    //     url: "https://dummyimage.com/300x400&text=surveys",
    //     associtaeWith: ["Homecare"],
    // },
    // {
    //     name: "Ad Module",
    //     key: "sdModule",
    //     url: "https://dummyimage.com/300x400&text=adModule",
    //     associtaeWith: ["Homecare", "Food", "Teaching"],
    // },
    // {
    //     name: "Media Gallery",
    //     key: "mediaGallery",
    //     url: "https://dummyimage.com/300x400&text=mediaGallery",
    //     associtaeWith: ["Homecare", "Food", "Teaching"],
    // },
    // {
    //     name: "Basic Content",
    //     key: "basicContent",
    //     url: "https://dummyimage.com/300x400&text=basicContent",
    //     associtaeWith: ["Homecare", "Food", "Teaching"],
    // },
    // {
    //     name: "Video Calling (QuickBlox)",
    //     key: "VideoCallingQuickBlox",
    //     url: "https://dummyimage.com/300x400&text=VideoCalling",
    //     associtaeWith: ["Homecare", "Food", "Teaching"],
    // },
    // {
    //     name: "SMS (Text Request, Twilio)",
    //     key: "sms",
    //     url: "https://dummyimage.com/300x400&text=sms",
    //     associtaeWith: ["Homecare", "Food", "Teaching"],
    // },
    // ...addonAppModules
];


export const appAdminPermissions = [
    { label: "Dashboard", key: 'dashboard' },
    { label: "Banner Management", key: 'bannerManagement' },
    { label: "Vendor Management", key: 'vendorManagement' },
    { label: "Category Management", key: 'categoryManagement' },
    { label: "Product Management", key: 'productManagement' },
    { label: "Order Management", key: 'orderManagement' },
    { label: "Customer Management", key: 'customerManagement' },
    { label: "Happylyfe Vendors", key: 'happylyfeVendors' },
    { label: "Happylyfe Deliveries", key: 'happylyfeDeliveries' },
    { label: "Withdrawal", key: 'withdrawal' },
    { label: "Donation Withdrawals", key: 'donationWithdrawals' },
    { label: "Promotion Management", key: 'promotionManagement' },
    { label: "New vendor requests", key: 'newVendorRequests' },
    { label: "Reviews", key: 'reviews' },
    { label: "Settings", key: 'settings' },
    { label: "Activity Logs", key: 'activityLogs' },
    { label: "CMS Login", key: 'blogCMSLogin' },
    // { label: "Donation CMS Login", key: 'donationCMSLogin' },

]
export const appClientPermissions = [
    "Dashboard",
    "Settings",
    "System Admins",
    "My Apps",
]
export const monthOptions = [
    { label: 'Jan', value: '1' },
    { label: 'Feb', value: '2' },
    { label: 'Mar', value: '3' },
    { label: 'Apr', value: '4' },
    { label: 'May', value: '5' },
    { label: 'Jun', value: '6' },
    { label: 'Jul', value: '7' },
    { label: 'Aug', value: '8' },
    { label: 'Sep', value: '9' },
    { label: 'Oct', value: '10' },
    { label: 'Nov', value: '11' },
    { label: 'Dec', value: '12' },
]
export const cdbCategoryConstant = [
    357,
    358,
    359,
    360,
    361,
    362,
    457,
    464,
    465,
    466
]