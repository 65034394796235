import { Ability, AbilityBuilder } from "@casl/ability";
import { PROFILE_KEY } from 'constants/AuthConstant';
import { ROLE } from 'constants/AppConstant';

const ability = new Ability();

export default (action, subject) => {

    setUserPermissions();

    let user = localStorage.getItem(PROFILE_KEY) || "";
    let rolePermission = null;
    if (user) {
        user = JSON.parse(user);
    }
    if (user && user?.rolePermissions) {
        rolePermission = user?.rolePermissions
    }
    if (((user?.role === ROLE.SUPERADMIN) || (user?.role === ROLE.CLIENT && !user?.clientId)) && !rolePermission) {
        return true;
    }
    return ability.can(action, subject);
    // return true
};

export const getSuperAdminAccess = () => {
    let user = localStorage.getItem(PROFILE_KEY) || "";
    if (user) {
        user = JSON.parse(user);
    }

    if (user?.role === ROLE.SUPERADMIN) {
        return true;
    }
    return false;
    // return true;
}


export const setUserPermissions = () => {
    let sUser = localStorage.getItem(PROFILE_KEY) || "";
    if (sUser) {
        sUser = JSON.parse(sUser);
    }
    let items = [];
    if (sUser?.rolePermissions && sUser?.rolePermissions?.permissions) {
        let permissionsObj = JSON.parse(sUser?.rolePermissions?.permissions);
        Object.keys(permissionsObj).map((val) => {
            if (permissionsObj[val].list === true) {
                items.push(val);
            }
        })
    }

    return ability.update(defineRulesFor(items || []));
}

const defineRulesFor = (permissions) => {
    const { can, rules } = new AbilityBuilder();


    if (permissions) {
        permissions.forEach((p) => {
            can('access', p);
        });
    }
    return rules;
};