import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'
import { ROLE } from '../constants/AppConstant';

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'reset-password',
        path: `${AUTH_PREFIX_PATH}/reset-password/:token`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password copy')),
    }
]

export const protectedRoutes = [
    {
        key: 'myProfile',
        path: `${APP_PREFIX_PATH}/my-profile`,
        component: React.lazy(() => import('views/app-views/my-profile')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard',
        path: `${APP_PREFIX_PATH}/dashboard`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.admins',
        path: `${APP_PREFIX_PATH}/users`,
        breadcrumb: true,
        title: 'Admin Management',
        component: React.lazy(() => import('views/app-views/users-management/list')),
        role: [ROLE.SUPERADMIN]
    },
    {
        key: 'dashboard.admins.view',
        path: `${APP_PREFIX_PATH}/users/view/:id`,
        component: React.lazy(() => import('views/app-views/users-management/View')),
        role: [ROLE.SUPERADMIN]
    },
    {
        key: 'dashboard.admins.update',
        path: `${APP_PREFIX_PATH}/users/update/:id`,
        component: React.lazy(() => import('views/app-views/users-management/Update')),
        role: [ROLE.SUPERADMIN]
    },
    {
        key: 'dashboard.admins.add',
        path: `${APP_PREFIX_PATH}/users/add`,
        component: React.lazy(() => import('views/app-views/users-management/Add')),
        role: [ROLE.SUPERADMIN]
    },
    {
        key: 'dashboard.role-management',
        path: `${APP_PREFIX_PATH}/roles`,
        breadcrumb: true,
        title: "Role Management",
        component: React.lazy(() => import('views/app-views/role-management/list')),
        role: [ROLE.SUPERADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.role-management.add',
        path: `${APP_PREFIX_PATH}/roles/add`,
        breadcrumb: true,
        title: "Add Role",
        component: React.lazy(() => import('views/app-views/role-management/Add')),
        role: [ROLE.SUPERADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.role-management.update',
        path: `${APP_PREFIX_PATH}/roles/update/:id`,
        breadcrumb: true,
        title: "Update Role",
        component: React.lazy(() => import('views/app-views/role-management/Update')),
        role: [ROLE.SUPERADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.role-management.permissions',
        path: `${APP_PREFIX_PATH}/roles/permissions/:id`,
        component: React.lazy(() => import('views/app-views/role-management/View')),
        role: [ROLE.SUPERADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.vendors',
        path: `${APP_PREFIX_PATH}/vendors`,
        component: React.lazy(() => import('views/app-views/vendor-management/list')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.happyvendors',
        path: `${APP_PREFIX_PATH}/happy-vendors`,
        component: React.lazy(() => import('views/app-views/vendor-management/list')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.vendors.add',
        path: `${APP_PREFIX_PATH}/vendors/add`,
        breadcrumb: true,
        title: "Add a vendor",
        component: React.lazy(() => import('views/app-views/vendor-management/Add')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.vendors.view',
        path: `${APP_PREFIX_PATH}/vendors/view/:id`,
        breadcrumb: true,
        title: "Vendors Detail",
        component: React.lazy(() => import('views/app-views/vendor-management/View')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.vendors.update',
        breadcrumb: true,
        title: "Update vendor",
        maintitle: "Update vendor",
        path: `${APP_PREFIX_PATH}/vendors/update/:id`,
        component: React.lazy(() => import('views/app-views/vendor-management/Update')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.vendor_approval',
        path: `${APP_PREFIX_PATH}/vendors/approval`,
        component: React.lazy(() => import('views/app-views/vendor-approval-management/list')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.vendor_approval',
        path: `${APP_PREFIX_PATH}/vendors/approval/view/:id`,
        breadcrumb: true,
        title: "Vendors Request Detail",
        component: React.lazy(() => import('views/app-views/vendor-approval-management/View')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.customer',
        path: `${APP_PREFIX_PATH}/customer`,
        component: React.lazy(() => import('views/app-views/customer-management/list')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.customer.add',
        path: `${APP_PREFIX_PATH}/customer/add`,
        breadcrumb: true,
        title: "Add a customer",
        component: React.lazy(() => import('views/app-views/customer-management/Add')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.customer.view',
        path: `${APP_PREFIX_PATH}/customer/view/:id`,
        breadcrumb: true,
        title: "Customer Details",
        component: React.lazy(() => import('views/app-views/customer-management/View')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.customer.update',
        breadcrumb: true,
        title: "Update customer",
        maintitle: "Update customer",
        path: `${APP_PREFIX_PATH}/customer/update/:id`,
        component: React.lazy(() => import('views/app-views/customer-management/Update')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.banner',
        path: `${APP_PREFIX_PATH}/banner`,
        component: React.lazy(() => import('views/app-views/banner-management/list')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.banner.add',
        path: `${APP_PREFIX_PATH}/banner/add`,
        breadcrumb: true,
        title: "Add a Banner",
        component: React.lazy(() => import('views/app-views/banner-management/Add')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.banner.view',
        path: `${APP_PREFIX_PATH}/banner/view/:id`,
        breadcrumb: true,
        title: "Banner Details",
        component: React.lazy(() => import('views/app-views/banner-management/View')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.banner.update',
        breadcrumb: true,
        title: "Update Banner",
        maintitle: "Update Banner",
        path: `${APP_PREFIX_PATH}/banner/update/:id`,
        component: React.lazy(() => import('views/app-views/banner-management/Update')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.order',
        path: `${APP_PREFIX_PATH}/order-history`,
        component: React.lazy(() => import('views/app-views/order-management/list')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT, ROLE.MANAGER]
    },
    {
        key: 'dashboard.order.view',
        path: `${APP_PREFIX_PATH}/order-history/view/:id`,
        breadcrumb: true,
        title: "Order Details",
        component: React.lazy(() => import('views/app-views/order-management/View')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT, ROLE.MANAGER]
    },
    {
        key: 'dashboard.happylyfe-delivery',
        path: `${APP_PREFIX_PATH}/happylyfe-delivery`,
        component: React.lazy(() => import('views/app-views/happylyfe-order-management/list')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT, ROLE.MANAGER]
    },
    {
        key: 'dashboard.happylyfe-delivery.view',
        path: `${APP_PREFIX_PATH}/happylyfe-delivery/view/:id`,
        breadcrumb: true,
        title: "Order Details",
        component: React.lazy(() => import('views/app-views/happylyfe-order-management/View')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT, ROLE.MANAGER]
    },
    {
        key: 'dashboard.category',
        path: `${APP_PREFIX_PATH}/category`,
        component: React.lazy(() => import('views/app-views/category-management/list')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.category.add',
        path: `${APP_PREFIX_PATH}/category/add`,
        breadcrumb: true,
        title: "Add a category",
        component: React.lazy(() => import('views/app-views/category-management/Add')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.category.view',
        path: `${APP_PREFIX_PATH}/category/view/:id`,
        breadcrumb: true,
        title: "Category Details",
        component: React.lazy(() => import('views/app-views/category-management/View')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.category.update',
        breadcrumb: true,
        title: "Update category",
        maintitle: "Update category",
        path: `${APP_PREFIX_PATH}/category/update/:id`,
        component: React.lazy(() => import('views/app-views/category-management/Update')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.facets',
        path: `${APP_PREFIX_PATH}/facets`,
        component: React.lazy(() => import('views/app-views/facets/list')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.facets.add',
        path: `${APP_PREFIX_PATH}/facets/add`,
        breadcrumb: true,
        title: "Add a facet",
        component: React.lazy(() => import('views/app-views/facets/Add')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.facets.view',
        path: `${APP_PREFIX_PATH}/facets/view/:id`,
        breadcrumb: true,
        title: "Facet Details",
        component: React.lazy(() => import('views/app-views/facets/View')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.facets.update',
        breadcrumb: true,
        title: "Update facet",
        maintitle: "Update facet",
        path: `${APP_PREFIX_PATH}/facets/update/:id`,
        component: React.lazy(() => import('views/app-views/facets/Update')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.products',
        path: `${APP_PREFIX_PATH}/products`,
        component: React.lazy(() => import('views/app-views/product-management/list')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.products.add',
        path: `${APP_PREFIX_PATH}/products/add`,
        breadcrumb: true,
        title: "Add a products",
        component: React.lazy(() => import('views/app-views/product-management/Add')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.products.view',
        path: `${APP_PREFIX_PATH}/products/view/:id`,
        breadcrumb: true,
        title: "Products Detail",
        component: React.lazy(() => import('views/app-views/product-management/View')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.products.update',
        breadcrumb: true,
        title: "Update product",
        maintitle: "Update product",
        path: `${APP_PREFIX_PATH}/products/update/:id`,
        component: React.lazy(() => import('views/app-views/product-management/Update')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.products.manage',
        breadcrumb: true,
        title: "Manage Variants",
        maintitle: "Manage Variants",
        path: `${APP_PREFIX_PATH}/products/:id/manage-variants`,
        component: React.lazy(() => import('views/app-views/product-management/Manage')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    //   
    {
        key: 'dashboard.happylyfe_discount',
        path: `${APP_PREFIX_PATH}/happylyfe-discount`,
        component: React.lazy(() => import('views/app-views/happylyfe-discount-management/list')),
        role: [ROLE.CLIENT]
    },
    {
        key: 'dashboard.discount',
        path: `${APP_PREFIX_PATH}/discount`,
        component: React.lazy(() => import('views/app-views/discount-management/list')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.discount.add',
        path: `${APP_PREFIX_PATH}/discount/add`,
        breadcrumb: true,
        title: "Add a discount",
        component: React.lazy(() => import('views/app-views/discount-management/Add')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },

    {
        key: 'dashboard.discount.update',
        breadcrumb: true,
        title: "Update promotion",
        maintitle: "Update promotion",
        path: `${APP_PREFIX_PATH}/discount/update/:id`,
        component: React.lazy(() => import('views/app-views/discount-management/Update')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    // 
    {
        key: 'dashboard.promotion',
        path: `${APP_PREFIX_PATH}/promotion`,
        component: React.lazy(() => import('views/app-views/promotion-management/list')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.promotion.add',
        path: `${APP_PREFIX_PATH}/promotion/add`,
        breadcrumb: true,
        title: "Add a promotion",
        component: React.lazy(() => import('views/app-views/promotion-management/Add')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.promotion.view',
        path: `${APP_PREFIX_PATH}/promotion/view/:id`,
        breadcrumb: true,
        title: "Promotion Details",
        component: React.lazy(() => import('views/app-views/promotion-management/View')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.promotion.update',
        breadcrumb: true,
        title: "Update promotion",
        maintitle: "Update promotion",
        path: `${APP_PREFIX_PATH}/promotion/update/:id`,
        component: React.lazy(() => import('views/app-views/promotion-management/Update')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.term_and_conditions',
        breadcrumb: true,
        title: "Term and Conditions",
        path: `${APP_PREFIX_PATH}/term-and-conditions`,
        component: React.lazy(() => import('views/app-views/vendor-management/term-conditions/View')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.shop',
        path: `${APP_PREFIX_PATH}/manage-store`,
        component: React.lazy(() => import('views/app-views/manage-store/View')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.review_approval',
        path: `${APP_PREFIX_PATH}/review-approval`,
        component: React.lazy(() => import('views/app-views/Product-review-management/list')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.MANAGER]
    },
    {
        key: 'dashboard.review_approval',
        path: `${APP_PREFIX_PATH}/review/add`,
        component: React.lazy(() => import('views/app-views/Product-review-management/Add')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.MANAGER]
    },
    {
        key: 'dashboard.donation',
        path: `${APP_PREFIX_PATH}/donation-history`,
        component: React.lazy(() => import('views/app-views/donation-management/list')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN]
    },
    {
        key: 'dashboard.settings',
        path: `${APP_PREFIX_PATH}/settings`,
        component: React.lazy(() => import('views/app-views/settings-management/View')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN]
    },
    {
        key: 'dashboard.withdrawal',
        path: `${APP_PREFIX_PATH}/payments`,
        component: React.lazy(() => import('views/app-views/withdrawal-management/list')),
        role: [ROLE.VENDOR]
    },
    {
        key: 'dashboard.withdrawal',
        path: `${APP_PREFIX_PATH}/withdrawal-history`,
        component: React.lazy(() => import('views/app-views/withdrawal-management/adminList')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN]
    },
    {
        key: 'dashboard.withdrawal.view',
        path: `${APP_PREFIX_PATH}/withdrawal-history/view/:id`,
        breadcrumb: true,
        title: "Vendor Withdrawals",
        component: React.lazy(() => import('views/app-views/withdrawal-management/View')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.bankDetail',
        path: `${APP_PREFIX_PATH}/bank-detail`,
        component: React.lazy(() => import('views/app-views/BankAccount-management/list')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.bankDetail.add',
        path: `${APP_PREFIX_PATH}/bank-detail/add`,
        breadcrumb: true,
        title: "Add a Bank Account",
        component: React.lazy(() => import('views/app-views/BankAccount-management/Add')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.bankDetail.view',
        path: `${APP_PREFIX_PATH}/bank-detail/view/:id`,
        breadcrumb: true,
        title: "Bank Account Detail",
        component: React.lazy(() => import('views/app-views/BankAccount-management/View')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.bankDetail.update',
        breadcrumb: true,
        title: "Update Bank Account",
        maintitle: "Update Bank Account",
        path: `${APP_PREFIX_PATH}/bank-detail/update/:id`,
        component: React.lazy(() => import('views/app-views/BankAccount-management/Update')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT]
    },
    {
        key: 'dashboard.notifications',
        path: `${APP_PREFIX_PATH}/notifications`,
        component: React.lazy(() => import('views/app-views/notifications/View')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.CLIENT, ROLE.VENDOR]
    },
    {
        key: 'dashboard.activity',
        path: `${APP_PREFIX_PATH}/activity`,
        component: React.lazy(() => import('views/app-views/activityLogs-management/list')),
        role: [ROLE.SUPERADMIN, ROLE.ADMIN]
    },
]