import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PROFILE_KEY, AUTH_TOKEN, S_AUTH_TOKEN, S_PROFILE_KEY } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import AuthService from 'services/AuthService';
import { setUserPermissions } from '../can';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	user: localStorage.getItem(PROFILE_KEY) ? JSON.parse(localStorage.getItem(PROFILE_KEY)) : null,
	token: localStorage.getItem(AUTH_TOKEN) || null,
	clientDetail: null
}


export const signIn = createAsyncThunk('auth/login', async (data, { rejectWithValue, dispatch }) => {
	const { email, password } = data
	try {
		const response = await AuthService.login({ username: email, password })
		const token = response?.token;
		localStorage.setItem(AUTH_TOKEN, token);
		localStorage.setItem(PROFILE_KEY, JSON.stringify({ ...response?.data }));
		localStorage.removeItem(S_AUTH_TOKEN);
		localStorage.removeItem(S_PROFILE_KEY);
		dispatch(signInSuccess(response))
		setUserPermissions();
		
		if(response?.data?.role === "Vendor" && response?.data?.profile?.isTermsConditions == 0){
			
			window.location.href = "/app/term-and-conditions";
			return token;
		}
		// window.location.href = "/";
		return token;
	} catch (err) {
		showLoading();
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const loginWithThisVendor = createAsyncThunk('auth/client', async (id, { rejectWithValue, dispatch }) => {

	try {
		const response = await AuthService.vendorLogin({ id })
		const token = response?.token;

		if (!localStorage.getItem(S_AUTH_TOKEN) || !localStorage.getItem(S_PROFILE_KEY)) {
			localStorage.setItem(S_AUTH_TOKEN, localStorage.getItem(AUTH_TOKEN));
			localStorage.setItem(S_PROFILE_KEY, localStorage.getItem(PROFILE_KEY));
		}

		localStorage.setItem(AUTH_TOKEN, token);
		localStorage.setItem(PROFILE_KEY, JSON.stringify(response?.data));
		setUserPermissions();
		dispatch(signInSuccess(response));
		window.location.href = "/app/dashboard";
		return token;
	} catch (err) {
		showLoading();
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signUp = createAsyncThunk('auth/register', async (data, { rejectWithValue }) => {
	const { email, password } = data
	try {
		const response = await AuthService.register({ email, password })
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		localStorage.removeItem(S_AUTH_TOKEN);
		localStorage.removeItem(S_PROFILE_KEY);
		localStorage.setItem("selectedAppTemplate", '');
		return token;
	} catch (err) {
		showLoading();
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signOut = createAsyncThunk('auth/logout', async () => {
	const response = await FirebaseService.signOutRequest()
	localStorage.removeItem(AUTH_TOKEN);
	localStorage.removeItem(PROFILE_KEY);
	localStorage.removeItem(S_AUTH_TOKEN);
	localStorage.removeItem(S_PROFILE_KEY);
	localStorage.setItem("selectedAppTemplate", '');
	return response.data
})

export const signInWithGoogle = createAsyncThunk('auth/signInWithGoogle', async (_, { rejectWithValue }) => {
	try {
		const response = await AuthService.loginInOAuth()
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);

		localStorage.removeItem(S_AUTH_TOKEN);
		localStorage.removeItem(S_PROFILE_KEY);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signInWithFacebook = createAsyncThunk('auth/signInWithFacebook', async (_, { rejectWithValue }) => {
	try {
		const response = await AuthService.loginInOAuth()
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);

		localStorage.removeItem(S_AUTH_TOKEN);
		localStorage.removeItem(S_PROFILE_KEY);
		return token;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
			state.user = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.user = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = !state.loading
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload.token
			state.user = action.payload.data
		},
		updateUserdetail: (state, action) => {
			state.loading = false
			state.user = action.payload
		},
		updateClientAppDetail: (state, action) => {
			state.clientDetail = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithGoogle.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithGoogle.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithGoogle.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
			.addCase(signInWithFacebook.pending, (state) => {
				state.loading = true
			})
			.addCase(signInWithFacebook.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signInWithFacebook.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})
	},
})

export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess,
	updateUserdetail,
	updateClientAppDetail
} = authSlice.actions

export default authSlice.reducer