import React  from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig'
import { ROLE } from 'constants/AppConstant';

const PublicRoute = () => {

	const { token, user } = useSelector(state => state.auth)

	if(token && user && user?.rolePermissions && user?.rolePermissions?.code === ROLE.MANAGER){
		return <Navigate to={"/app/products"} />
	}
  
	return token ? <Navigate to={AUTHENTICATED_ENTRY} /> : <Outlet/>
}

export default PublicRoute